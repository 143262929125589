import React from "react";
import { ModalContextProvider } from "./contexts/ModalContext";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider } from "wagmi";
import { config } from "./walletConfig";
import { WrappedRainbowKitProvider } from "@/contexts/WrappedRainbowKitProvider";
import { BridgeSocketProvider } from "./contexts/BridgeSocket";
import { ChainInitiationContextProvider } from "./contexts/ChainInitiationContext";
var queryClient = new QueryClient();
export var AppContext = function (_a) {
    var children = _a.children;
    return (React.createElement(WagmiProvider, { config: config },
        React.createElement(QueryClientProvider, { client: queryClient },
            React.createElement(WrappedRainbowKitProvider, null,
                React.createElement(ChakraProvider, { theme: theme },
                    React.createElement(BridgeSocketProvider, null,
                        React.createElement(ModalContextProvider, null,
                            " ",
                            React.createElement(ChainInitiationContextProvider, null, children))))))));
};
