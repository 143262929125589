var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from "react";
import { Box, Flex, Text, Image, Tooltip, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Button, } from "@chakra-ui/react";
import { useAuthToken } from "@/hooks/useAuthToken";
import { useAccount } from "wagmi";
import axios from "axios";
import { BRIDGE_SERVER_URL } from "@/env";
export var BPToggleSwitch = function (_a) {
    var onToggle = _a.onToggle;
    var token = useAuthToken()[0];
    var address = useAccount().address;
    var _b = useState(null), selectedBP = _b[0], setSelectedBP = _b[1];
    var _c = useState(null), bpData = _c[0], setBPData = _c[1];
    var bpImages = ["/bp0.png", "/bp2.png", "/bp1.png"];
    var bpTypes = [
        "Normal (Season 5)",
        "Vetra's Wrap (Season 5 Basic)",
        "Vashin's Shroud (Season 5 Premium)",
    ];
    var _d = useState(false), allowTooltips = _d[0], setAllowTooltips = _d[1];
    var _e = useState(false), isConfirmOpen = _e[0], setIsConfirmOpen = _e[1];
    var _f = useState(null), pendingBPIndex = _f[0], setPendingBPIndex = _f[1];
    useEffect(function () {
        var timer = setTimeout(function () { return setAllowTooltips(true); }, 500);
        return function () { return clearTimeout(timer); };
    }, []);
    useEffect(function () {
        console.log("isConfirmOpen", isConfirmOpen);
        console.log("pendingBPIndex", pendingBPIndex);
    }, [isConfirmOpen]);
    useEffect(function () {
        var fetchCurrentBP = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, data, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!address) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, axios.get("".concat(BRIDGE_SERVER_URL, "/battlepass/get_bp_chosen/").concat(address))];
                    case 2:
                        response = _a.sent();
                        console.log("Current BP response:", response.data);
                        data = response.data;
                        setBPData(data);
                        setSelectedBP(data.bpSelected);
                        onToggle(data.bpSelected);
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.error("Error fetching current BP:", error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        fetchCurrentBP();
    }, [address, onToggle]);
    var handleToggle = function (index) { return __awaiter(void 0, void 0, void 0, function () {
        var bpIndex;
        return __generator(this, function (_a) {
            bpIndex = index === 0 ? 0 : index === 1 ? 2 : 1;
            setPendingBPIndex(bpIndex);
            setIsConfirmOpen(true);
            return [2 /*return*/];
        });
    }); };
    var confirmToggle = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(pendingBPIndex !== null)) return [3 /*break*/, 4];
                    setSelectedBP(pendingBPIndex);
                    onToggle(pendingBPIndex);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.post("".concat(BRIDGE_SERVER_URL, "/battlepass/change_bp"), {
                            wallet: address,
                            token: token,
                            bp: pendingBPIndex,
                        })];
                case 2:
                    response = _a.sent();
                    console.log("BP change response:", response.data);
                    console.log("Successfully changed BP to ".concat(bpTypes[pendingBPIndex]));
                    window.location.reload();
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    console.error("Error changing BP:", error_2);
                    return [3 /*break*/, 4];
                case 4:
                    setIsConfirmOpen(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var renderBPOption = function (index) {
        var bpIndex = index;
        var isAvailable = bpIndex === 0 ||
            (bpIndex === 2 && (bpData === null || bpData === void 0 ? void 0 : bpData.hasPremium)) ||
            (bpIndex === 1 && (bpData === null || bpData === void 0 ? void 0 : bpData.hasBasic));
        console.log("isAvailable", isAvailable);
        return (React.createElement(Tooltip, { key: index, label: isAvailable ? bpTypes[index] : "".concat(bpTypes[index], " (Not Owned)"), placement: "top", hasArrow: true, bg: "gray.700", color: "white", fontSize: "sm", padding: "2", borderRadius: "md", isDisabled: !allowTooltips },
            React.createElement(Box, { as: "button", width: "40px", height: "40px", onClick: function () { return isAvailable && handleToggle(index); }, position: "relative", borderRadius: "full", overflow: "hidden", border: selectedBP === (index === 0 ? 0 : index === 1 ? 2 : 1)
                    ? "2px solid #D4AF37"
                    : "none", cursor: isAvailable ? "pointer" : "not-allowed" },
                React.createElement(Image, { src: bpImages[index], alt: "BP ".concat(bpTypes[index]), width: "120%", height: "120%", objectFit: "cover", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", filter: selectedBP === bpIndex
                        ? "none"
                        : isAvailable
                            ? "brightness(0.5)"
                            : "brightness(0.2) grayscale(100%)" }),
                !isAvailable && (React.createElement(Box, { position: "absolute", top: "50%", left: "0", width: "100%", height: "2px", bg: "rgba(255, 0, 0, 0.5)", transform: "rotate(-45deg)" })))));
    };
    return (React.createElement(Flex, { direction: "column", align: "center", ml: 2 },
        React.createElement(Text, { fontSize: "xs", fontWeight: "bold", mb: 1, color: "whiteAlpha.700" }, "Battle Pass"),
        React.createElement(Flex, { justify: "space-between", width: "140px", height: "40px" }, [0, 1, 2].map(renderBPOption)),
        React.createElement(Modal, { isOpen: isConfirmOpen, onClose: function () { return setIsConfirmOpen(false); }, isCentered: true, size: "sm" },
            React.createElement(ModalOverlay, null),
            React.createElement(ModalContent, { bg: "gray.800", color: "white" },
                React.createElement(ModalHeader, null, "Confirm Battle Pass Selection"),
                React.createElement(ModalBody, null,
                    "Are you sure you want to select",
                    " ",
                    pendingBPIndex !== null
                        ? bpTypes[pendingBPIndex === 0 ? 0 : pendingBPIndex === 1 ? 2 : 1]
                        : "",
                    " ",
                    "Battle Pass? Confirming will refresh the page."),
                React.createElement(ModalFooter, null,
                    React.createElement(Button, { colorScheme: "blue", mr: 3, onClick: confirmToggle }, "Yes"),
                    React.createElement(Button, { variant: "ghost", onClick: function () { return setIsConfirmOpen(false); } }, "Cancel"))))));
};
